import { Text, Flex, Box, hasCloudinaryAsset, CldImage, Link, Heading } from "@project/ui";

import {
    type MicrocopyInputType,
    useMicrocopy,
} from "@/lib/contentful/microcopy/microcopy-context";
import type { ModuleCarTechSpecsFieldsFragment } from "@/components/contentful/module-car-tech-specs/__generated/module-car-tech-specs.contentful.generated";
import { ModuleSpacer } from "@/components/module-spacer";
import { Title } from "@/components/title";

type ModuleCarTechSpecsProps = ModuleCarTechSpecsFieldsFragment & {
    microcopySet: MicrocopyInputType;
};

export const ModuleCarTechSpecs = ({ car, microcopySet }: ModuleCarTechSpecsProps) => {
    const { get: getMicrocopy, add: addMicrocopy } = useMicrocopy();
    addMicrocopy(microcopySet);

    const specs = [
        { name: getMicrocopy("moduleCarTechSpecs", "engine"), value: car?.engine },
        { name: getMicrocopy("moduleCarTechSpecs", "displacement"), value: car?.displacement },
        { name: getMicrocopy("moduleCarTechSpecs", "performance"), value: car?.power },
        { name: getMicrocopy("moduleCarTechSpecs", "gears"), value: car?.transmission },
        { name: getMicrocopy("moduleCarTechSpecs", "weight"), value: car?.weight },
        { name: getMicrocopy("moduleCarTechSpecs", "driveLine"), value: car?.driveline },
    ].filter(({ value }) => value);

    return (
        <ModuleSpacer>
            <Flex flexDirection="column" mb={{ base: 6, s: 14 }}>
                <Flex mb={{ base: 8, l: 10 }} flexDirection="column">
                    <Text size="caption" color="grey300">
                        {car?.name}
                    </Text>
                    <Title>{getMicrocopy("moduleCarTechSpecs", "performanceInNumbers")}</Title>
                </Flex>
                {hasCloudinaryAsset(car?.asset) && (
                    <Box
                        as="figure"
                        w={{ base: "100%", s: "80%" }}
                        mx="auto"
                        h={{
                            base: 200,
                            md: 340,
                            l: 450,
                        }}
                    >
                        <CldImage
                            cloudinaryAsset={car?.asset}
                            sizes={["100vw"]}
                            priority={true}
                            _hover={{ transform: "scale(1.02)" }}
                            transition="transform 0.30s ease"
                            objectFit="contain"
                            rawTransformations="c_fill,ar_16:9,e_trim:5"
                            wrapperProps={{
                                overflow: "visible",
                            }}
                        />
                    </Box>
                )}
                <Box mt={{ base: 0, s: -10 }}>
                    {specs.map(({ name, value }) => (
                        <Flex
                            key={name}
                            py={{ base: 4, s: 6 }}
                            gap={{ base: 1, s: 4 }}
                            flexDirection={{ base: "column", s: "row" }}
                            justifyContent={"space-between"}
                            alignItems="start"
                            borderBottomWidth={1}
                            borderBottomColor={"grey200"}
                        >
                            <Heading size="medium" mb={0} width={{ base: "full", s: "33.333%" }}>
                                {name}
                            </Heading>
                            <Text
                                size="small"
                                mb={0}
                                width={{ base: "full", s: "66.666%" }}
                                textAlign={{ base: "left", s: "right" }}
                            >
                                {value}
                            </Text>
                        </Flex>
                    ))}
                </Box>
            </Flex>
            {car?.factSheetPdf?.url ? (
                <Link
                    href={car?.factSheetPdf.url}
                    target="_blank"
                    icon="download"
                    width={{ base: "100%", s: "auto" }}
                >
                    {getMicrocopy("moduleCarTechSpecs", "downloadFactSheet")}
                </Link>
            ) : null}
        </ModuleSpacer>
    );
};
