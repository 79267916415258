import { PLinkTile } from "@porsche-design-system/components-react/ssr";
import type { PLinkTileProps } from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkTileProps = Omit<NextLinkProps, "as"> & Omit<PLinkTileProps, "href">;

const ChakraPLinkTile = chakra<typeof PLinkTile, LinkTileProps>(PLinkTile);

const LinkTile = forwardRef<LinkTileProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLinkTile {...props} ref={ref} />;
    }

    const {
        // NextLink props
        download,
        href,
        rel,
        target,
        replace = false,
        scroll = true,
        shallow = false,
        locale,
        children,
        //
        ...rest
    } = props;

    return (
        <ChakraPLinkTile {...rest}>
            <NextLink
                download={download}
                href={href}
                rel={rel}
                target={target}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                ref={ref}
            >
                {children}
            </NextLink>
        </ChakraPLinkTile>
    );
});

export { LinkTile };
export type { LinkTileProps };
