import { PLinkTileProduct } from "@porsche-design-system/components-react/ssr";
import type {
    PLinkTileProductProps,
    LinkTileProductLikeEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkTileProductProps = Omit<NextLinkProps, "as"> & Omit<PLinkTileProductProps, "href">;

const ChakraPLinkTileProduct = chakra<typeof PLinkTileProduct, LinkTileProductProps>(
    PLinkTileProduct
);

const LinkTileProduct = forwardRef<LinkTileProductProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLinkTileProduct {...props} ref={ref} />;
    }

    const {
        // NextLink props
        download,
        href,
        rel,
        target,
        replace = false,
        scroll = true,
        shallow = false,
        locale,
        children,
        //
        ...rest
    } = props;

    return (
        <ChakraPLinkTileProduct {...rest}>
            <NextLink
                download={download}
                href={href}
                rel={rel}
                target={target}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                ref={ref}
            >
                {children}
            </NextLink>
        </ChakraPLinkTileProduct>
    );
});

export { LinkTileProduct };
export type { LinkTileProductProps, LinkTileProductLikeEventDetail };
