import { useRouter } from "next/router";
import { useEffect } from "react";
import { PAGMSHEvents, sendPagDataToGTM } from "@/lib/google-tag-manager/events";

const usePAGMSHGeneralPageLoad = ({
    contentTags,
    pageType,
}: {
    contentTags: string[];
    pageType: string;
}): void => {
    const { locale, asPath } = useRouter();

    useEffect(() => {
        sendPagDataToGTM({
            locale: locale!,
            eventAction: PAGMSHEvents.pageLoad,
            pageExperience: {
                pageCategory: pageType,
                contentTags: contentTags ?? [],
            },
        });
    }, [asPath, locale, contentTags, pageType]);
};

export { usePAGMSHGeneralPageLoad };
