import { PLink } from "@porsche-design-system/components-react/ssr";
import type { PLinkProps } from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkProps = Omit<NextLinkProps, "as" | "href"> & PLinkProps;

const ChakraPLink = chakra<typeof PLink, LinkProps>(PLink);

const Link = forwardRef<LinkProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLink {...props} ref={ref} />;
    }

    const {
        // NextLink props
        download,
        href,
        rel,
        target,
        replace = false,
        scroll = true,
        shallow = false,
        locale,
        children,
        //
        ...rest
    } = props;

    return (
        <ChakraPLink {...rest}>
            <NextLink
                download={download}
                href={href}
                rel={rel}
                target={target}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                ref={ref}
            >
                {children}
            </NextLink>
        </ChakraPLink>
    );
});

export { Link };
export type { LinkProps };
