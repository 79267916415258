import {
    themeDarkNotificationError,
    themeDarkNotificationWarningSoft,
    themeLightBackgroundBase,
    themeLightContrastHigh,
    themeLightNotificationErrorSoft,
    themeLightNotificationInfo,
    themeLightNotificationSuccess,
    themeLightNotificationWarning,
    themeLightNotificationWarningSoft,
    themeLightPrimary,
    themeLightStateDisabled,
    themeDarkBackgroundShading,
    themeLightBackgroundShading,
} from "@porsche-design-system/components-react/styles";

const colors = {
    porscheBlack: themeLightPrimary,
    porscheBlackShaded: themeLightBackgroundShading,
    allWhite: themeLightBackgroundBase,
    whiteOpaque: "rgba(255, 255, 255, 0.08)",
    blackOpaque: "rgba(0, 0, 0, 0.05)",
    motorsportsRed: "#E00000",
    backgroundRed: "#CA3230",
    grey700: "#6b6d70",
    grey600: "#808082",
    grey500: themeLightContrastHigh,
    grey400: themeLightStateDisabled,
    grey300: "#595959",
    grey200: "#CCCCCC",
    grey100: "#EEEFF2",
    grey50: "#F7F7F7",
    backgroundShading: themeDarkBackgroundShading,
    notificationError: themeDarkNotificationError,
    notificationErrorSoft: themeLightNotificationErrorSoft,
    notificationWarning: themeLightNotificationWarning,
    notificationWarningSoft: themeDarkNotificationWarningSoft,
    notificationSuccess: themeLightNotificationSuccess,
    notificationSuccessSoft: themeLightNotificationWarningSoft,
    notificationInfo: themeLightNotificationInfo,
    notificationInfoSoft: themeLightNotificationWarningSoft,
    transparent: "rgba(0, 0, 0, 0)",
    formulaE: "#4e288b",
};

export default colors;
