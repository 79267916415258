import { Box, Grid, GridItem, Text, List, ListItem } from "@project/ui";
import type { FooterFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import { LinkWrapper } from "@/components/link-wrapper";
import type { LinkWrapperProps } from "@/components/link-wrapper";
import { useMemo } from "react";
import { richTextRenderer } from "@/lib/contentful/rich-text-renderer/rich-text-renderer";
import { ModuleSpacer } from "@/components/module-spacer";
import { PAGMSHEvents } from "@/lib/google-tag-manager/events";

type FooterProps = FooterFieldsFragment;

type FooterTextLinkProps = LinkWrapperProps;

const FooterTextLink = ({ onClick, ...props }: FooterTextLinkProps) => {
    return (
        <LinkWrapper
            {...props}
            eventAction={PAGMSHEvents.footerLinkClick}
            theme="dark"
            icon="none"
        />
    );
};

export const Footer = (props: FooterProps) => {
    const quaternaryNavigation = props.quaternaryNavigation;

    const {
        copyrightText,
        legalDisclaimer,
        primaryNavigation,
        secondaryNavigation,
        tertiaryNavigation,
    } = useMemo(() => props, [props]);

    const currentYear = new Date().getFullYear().toString();
    const updatedCopyrightText = (copyrightText ?? "").replace("{year}", currentYear);

    return (
        <ModuleSpacer as="footer" bg="porscheBlack" color="allWhite" pb={10} py={0}>
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    s: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                }}
                gap={{ base: 12, l: 8 }}
                py={10}
            >
                <GridItem>
                    <List>
                        {primaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="2">
                                        <FooterTextLink size="x-large" item={item} />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {secondaryNavigation?.title}
                    </Text>
                    <List>
                        {secondaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink item={item} />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {tertiaryNavigation?.title}
                    </Text>
                    <List>
                        {tertiaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink item={item} />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
                <GridItem>
                    <Text size="caption" mb={4} color="grey600">
                        {quaternaryNavigation?.title}
                    </Text>
                    <List>
                        {quaternaryNavigation?.navigationItemsCollection?.items.map(
                            (item) =>
                                item && (
                                    <ListItem key={item.sys.id} mb="1">
                                        <FooterTextLink item={item} />
                                    </ListItem>
                                )
                        )}
                    </List>
                </GridItem>
            </Grid>
            <Text size="small" mb={4}>
                {updatedCopyrightText}
            </Text>
            {legalDisclaimer && (
                <Box className="rte" fontSize="xx-small">
                    {richTextRenderer(legalDisclaimer)}
                </Box>
            )}
        </ModuleSpacer>
    );
};
